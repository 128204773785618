.svgBackground {
  /* width: 300px;
  height: 300px; */
  display: block;
}

.sizing {
  /* position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  margin: 10px auto;
}

/* TODO: I think the names of these two classes are not... pretty */
.circleOne {
  fill: none;
}

.circleTwo {
  fill: none;
  transform: rotate(-0.25turn);
  transform-origin: center;
}

.svgBorder {
  border: 2px solid rgb(115, 187, 255);
}
