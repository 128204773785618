.upArrow {
  transform: rotate(-0.5turn);
  transform-origin: center;
}

.arrangeInput {
  display: block;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.4em 0;
  /* width: 100%; */
  width: 20%;
  font-size: 1.5em;
  /* font-weight: 200; */
}

.arrangeLabel {
  /* padding-left: 6.5em; */
  display: block;
  text-align: center;
  /* font-weight: bold; */
}

.alignBoxes {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.forBothArrows {
  width: 1.5em;
}

.saveButton {
  display: block;
  transform: translateX(50%);
}

.flexBox {
  display: flex;
  justify-content: center;
}

.centerForm {
  /* width: fit-content; */
  width: 50%;
  background-color: white;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  margin-top: 3em;
  padding: 3em 0;
  border-radius: 3em;
  border-color: black;
  border-right: 10px;
  border-bottom: 10px;
  border-style: solid;
}
