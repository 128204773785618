.span {
  font-size: max(0.75rem, 1.6212vh);
  color: #ffb86c;
  cursor: pointer;
  text-decoration: none;
}

.span:hover {
  font-weight: bold;
}

.burger {
  display: none;
}

.burgerDiv {
  width: 25px;
  height: 3px;
  background-color: rgb(65, 43, 43);
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .burger {
    display: block;
    cursor: pointer;
    padding: 5px;
  }
}

@keyframes navLinksFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
