* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-family: "Poppins", sans-serif;
  height: 100%;
}

body {
  /* min-height: 100vh; */
  min-height: 100%;
  background-color: #f0f0f0;
  user-select: none;
  overflow-x: hidden;
}

/* #root,
#root > div {
  height: 100%;
} */

/* https://www.youtube.com/watch?v=ru3U8MHbFFI&t=145s */
#root {
  /* height: 100vh;
  height: 100dvh; */
  min-height: 100vh;
  min-height: 100dvh;
}

.btn {
  border: none;
  font-size: 1rem;
  padding: 0.5em 1em;
  border-radius: 0.3em;
  cursor: pointer;
  background-color: rgb(233, 233, 233);
}

.btn:hover {
  background-color: rgb(226, 221, 221);
}

.btn-primary {
  background-color: rgb(129, 216, 201);
}

.btn-primary:hover {
  background-color: rgb(117, 187, 175);
}

.btn-blue {
  /* #96b2f3 */
  background-color: rgb(150, 178, 243);
}

.btn-blue:hover {
  /* #7997dc */
  background-color: rgb(121, 151, 220);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}
